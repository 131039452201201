import Api, { RequestType } from './Api';

export const api = new Api();

type GenericFetcherArgs = Omit<RequestType, 'method' | 'url'> & {
  method?: 'POST' | 'GET';
};
export const genericFetcher = (arg?: GenericFetcherArgs) =>
  function fetcher<Data>(url: string) {
    return api.makeRequest<Data>({ method: 'GET', url, ...arg }).then((response) => response.data);
  };
