import React from 'react';
import Head from 'next/head';
import Script from 'next/script';

type MetaProps = {
  title?: string;
  description?: string;
  link?: string;
};

// {
//   props.css
//   && <link rel="stylesheet" href={`${props.css}`} />
// }
// {
//   props.image ? (
//     <meta property="og:image" content={`${props.image}`} />
//   ) : (
//     <meta property="og:image" content="https://www.propernoun.co/static/images/proper-noun-social.png" />
//   )
// }
// {
//   props.image
//   && <meta name="twitter:image" content={`${props.image}`} />
// }
// {
//   props.canonical
//   && <link rel="canonical" href={`${props.canonical}`} />
// }
// {
//   props.js
//   && <script type="text/javascript" src={`${props.js}`} />
// }

const Meta: React.FC<MetaProps> = ({ title, description, link }) => (
  <Head>
    <title>{title ? `${title} | Zionodes` : 'Zionodes'}</title>
    <meta name="description" content={description} />
    <meta property="og:type" content="website" />
    <meta name="og:title" property="og:title" content={title} />
    <meta name="og:description" property="og:description" content={description} />
    <meta property="og:site_name" content="Zionodes" />
    <meta property="og:url" content={`${link}`} />
    <meta name="twitter:card" content="summary" />
    <meta name="twitter:title" content={title} />
    <meta name="twitter:description" content={description} />
    <meta name="twitter:site" content="@zionodes" />
    <meta name="twitter:creator" content="@zionodes" />
    <link rel="icon" href="/images/favicon-32x32.png?v=3d5e8a6f8" type="image/png" />
    <link rel="apple-touch-icon" sizes="48x48" href="/images/icon-48x48.png?v=3d5e8a6f8" />
    <link rel="apple-touch-icon" sizes="72x72" href="/images/icon-72x72.png?v=3d5e8a6f8" />
    <link rel="apple-touch-icon" sizes="96x96" href="/images/icon-96x96.png?v=3d5e8a6f8" />
    <link rel="apple-touch-icon" sizes="144x144" href="/images/icon-144x144.png?v=3d5e8a6f8" />
    <link rel="apple-touch-icon" sizes="192x192" href="/images/icon-192x192.png?v=3d5e8a6f8" />
    <link rel="apple-touch-icon" sizes="256x256" href="/images/icon-256x256.png?v=3d5e8a6f8" />
    <link rel="apple-touch-icon" sizes="384x384" href="/images/icon-384x384.png?v=3d5e8a6f8" />
    <link rel="apple-touch-icon" sizes="512x512" href="/images/icon-512x512.png?v=3d5e8a6f8" />

    <Script
      strategy="afterInteractive"
      dangerouslySetInnerHTML={{
        __html: `
      (function(h,o,t,j,a,r){
        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
        h._hjSettings={hjid:2687114,hjsv:6};
        a=o.getElementsByTagName('head')[0];
        r=o.createElement('script');r.async=1;
        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
        a.appendChild(r);
    })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`,
      }}
    />
  </Head>
);

export default Meta;
