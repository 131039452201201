import { AxiosResponse } from 'axios';
import { UseMarketplaceArg } from './marketplace';

export type WidthdawConfirmType = {
  non_field_errors?: string;
  address: string;
  amount: number;
};

export type JwtData = {
  access: string;
  access_expiration_timestamp: string;
  refresh: string;
  refresh_expiration_timestamp: string;
};

export type ProfileType = {
  first_name: string;
  last_name: string;
  image: string;
  balance: number;
  points: number;
  role: RoleEnum;
  reseller_type: ResellerRoleEnum;
  username?: string;
  total_trial?: number;
};

export type SpecialProfileType = {
  description: string;
  spec_phone: string;
  contact_email: string;
  contacts: {
    telegram: string;
    whatsapp: string;
    wechat: string;
  };
};

export type AsicGeneralConfigurationType = {
  pool_1: string;
  pool_2: string;
  pool_3: string;
  template: string;
  editable: boolean;
  password?: string;
};

export type ProfileAdditionalType = {
  address: string;
  nationality: string;
  birthday: string;
  phone: string;
};

export type ProfileSpecialType = {
  description: string;
  spec_phone: string;
  contact_email: string;
  contacts: {
    telegram?: string;
    whatsapp?: string;
    wechat?: string;
  };
};

export type ProfileCompanyType = {
  company_name: string;
  br_number: string;
  registered_address: string;
  registered_address_postal_code: string;
  company_email: string;
  country: string;
  business_address: string;
  business_address_postal_code: string;
};

export type ProfileLegalRepresentativeType = {
  first_name: string;
  last_name: string;
  birthday: string;
  email: string;
  nationality: string;
  job_title: string;
  phone_number: string;
};

export type TokenType = {
  user_id: string;
};

export type JWTLogin = {
  jwt: JwtData;
  full_profile: ProfileType;
};
type Request2FALogin = {
  ephemeral_token: string;
  method: 'app';
};

type LoginDataType = JWTLogin | Request2FALogin;

export type AsicDataType = {
  id: string;
  model: {
    id: string;
    model: string;
    image?: string;
    declared_hashrate: number;
    nominal_hashrate: number;
    nominal_consumption: number;
  };
  description: string;
  is_online: boolean;
  status: string;
  nominal_hashrate: number;
  declared_hashrate: number;
  nominal_consumption: number;
  efficiency: number;
  reseller: {
    id: string;
    username: string;
    image: string;
    first_name: string;
    last_name: string;
    reviews_count: number;
  };
  farm_operator: {
    username: string;
    image: string;
    id: string;
    first_name: string;
    last_name: string;
    reviews_count: number;
  };
  amount: number;
  average_values: {
    time_online: string;
    efficiency: number;
    uptime: number;
  };
  created_at: string;
  electricity_prices: {
    m2_price: number;
    m3_price: number;
    m6_price: number;
    m12_price: number;
  };

  prices: Array<{
    min_quantity: number;
    max_quantity: number;
    price: number;
  }>;
  images: { image: string | undefined }[];
  purchase_date: string;
  user_for_sale: string | null;
  hardware?: MarketplaceItemHardwareType[];
  trial: boolean;
};

export type AsicsDataType = {
  results: Array<AsicDataType>;
  count: number;
  next: number | null;
  previous: number | null;
};

export type MyHardwareParams = {
  // Asic model ID
  model?: string;

  // Farm operator ID
  batch__farm_operator?: string;

  page: number;
  page_size: number;
  pool?: string;
  pool_user?: string;
};
export type MyHardware = {
  id: string;
  model: {
    id: string;
    model: string;
  };
  farm_operator: Pick<FarmOperatorType, 'id' | 'image' | 'username'>;
  hardware_stats: {
    online: boolean;
    online_time: string;
    hash: number;
    av_temp: number;
    cooler: number;
  };
  pool: string;
  pool_user: string;
  electricity_per_month: number;
  income_per_month: number;
  tokenized: boolean;
};
export type MyHardwareData = {
  count: number;
  next?: string;
  previous?: string;
  results: MyHardware[];
};

export type MyHardwareFilters = {
  models: {
    id: string;
    model: string;
  }[];
  farm_operators: {
    id: string;
    fo_username: string;
  }[];
  pools: {
    pool: string;
  }[];
  pools_users: {
    pool_user: string;
  }[];
};

export type InUseParams = {
  // Asic model ID
  model?: string;

  // Farm operator ID
  farm_operator__id?: string;

  // Purchase date
  purchase_date?: string;

  page: number;
  page_size: number;
};

export enum SoldOutStatus {
  ON_SALE = 'ON_SALE',
  SOLD = 'SOLD',
  TRIAL = 'TRIAL',
  ALL = ' ',
}

export type MarketplaceParams = {
  // Asic model ID
  model?: string;

  // Reseller ID
  reseller__id?: string;

  // Farm operator ID
  farm_operator__id?: string;

  status?: SoldOutStatus;
  trial?: boolean;

  min?: number;
  max?: number;

  page: number;
  page_size: number;
};
export type FarmOperatorsPageParams = {
  page: number;
  page_size: number;
};
export type InUseDetailsParams = {
  page: number;
  page_size: number;
};
export type ResellerDetailsParams = {
  page: number;
  page_size: number;
};
export type FarmOperatorDetailsParams = {
  page: number;
  page_size: number;
};
export type InUse = {
  id: string;
  model: {
    id: string;
    model: string;
    nominal_hashrate: number;
  };
  prices: {
    min: number;
    max: number;
  };
  status: {
    online: number;
    offline: number;
  };
  farm_operator: Pick<FarmOperatorType, 'id' | 'username'>;
  batch_status: ModelStatusType;
  purchase_date: string;
};
export type Marketplace = {
  id: string;
  model: {
    id: string;
    model: string;
  };
  amount: number;
  prices: {
    price: number;
  }[];
  created_at: string;
  reseller: {
    username: string;
  };
  images: { image: string | undefined }[];
  farm_operator: {
    username: string;
  };
  average_values: {
    time_online: string;
    efficiency: number;
    uptime: number;
  };
  user_for_sale: string | null;
  hardware?: MarketplaceItemHardwareType[];
  sold_out?: boolean;
  trial?: boolean;
};
export type FarmOperatorsPage = {
  id: string;
  first_name: string;
  last_name: string;
  rating: number;
  reviews_count: number;
  created_at: string;
  total_hashrate: number;
  image: string;
  monthly_electricity_cost: {
    m2_price: number;
    m12_price: number;
  };
  available_hardware: number;
  items_on_zionodes: number;
  coming_soon: boolean;
  available_items_fake: number;
  total_items_fake: number;
  total_hashrate_fake: number;
};
export type ResellerHardware = {
  id: string;
  model: string;
  declared_hashrate: number;
  nominal_consumption: number;
  income: number;
  items: number;
  image: string;
  batches: {
    id: string;
    items_count: number;
    fo_id: string;
    fo_username: string;
    fo_image: string;
    fo_rating: number;
    fo_reviews_count: number;
    description: string;
    electricity_prices: {
      m2_price: number;
      m3_price: number;
      m6_price: number;
      m12_price: number;
    };
    prices: {
      min_quantity: number;
      max_quantity: number;
      price: number;
    }[];
    user_for_sale: string | null;
  }[];
};
export type FakeFarmOperatorHardware = {
  id: string;
  farm_operator: { id: string; username: string };
  model: string;
  declared_hashrate: number;
  items: number;
  nominal_consumption: number;
  batches: Batch[];
  created_at: string;
  income?: number;
  reseller: { id: string | null; username: string };
};
export type FarmOperatorHardware = {
  id: string;
  model: string;
  declared_hashrate: number;
  nominal_consumption: number;
  income: number;
  items: number;
  image: string;
  batches: {
    id: string;
    items_count: number;
    reseller_id: string;
    reseller_username: string;
    reseller_image: string;
    reseller_rating: number;
    reseller_reviews_count: number;
    description: string;
    electricity_prices: {
      m2_price: number;
      m3_price: number;
      m6_price: number;
      m12_price: number;
    };
    prices: {
      min_quantity: number;
      max_quantity: number;
      price: number;
    }[];
    user_for_sale: string | null;
  }[];
};
export type ResellerReviews = {
  id: string;
  text: string;
  score: number;
  created_at: string;
  user: {
    first_name: string;
    last_name: string;
    username: string;
    image: string;
  };
};
export type FarmOperatorReviews = {
  id: string;
  text: string;
  score: number;
  created_at: string;
  user: {
    first_name: string;
    last_name: string;
    username: string;
    image: string;
  };
};
export type InUseDetails = {
  id: string;
  model: {
    id: string;
    model: string;
    nominal_hashrate: number;
  };
  price: {
    min: number;
    max: number;
  };
  status: {
    online: boolean;
    time: string;
  };
  farm_operator: Pick<FarmOperatorType, 'id' | 'username'>;
  purchase_date: string;
  batch_status: ModelStatusType;
  tokenized: boolean;
};
export type InUseData = {
  count: number;
  next?: string;
  previous?: string;
  results: InUse[];
};
export type GroupedBatch = {
  model: string;
  batches: {
    id: string;
    amount: number;
    user_for_sale: string | null;
    prices: {
      price: number;
    }[];
    created_at: string;
    reseller: {
      username: string;
    };
    images: {
      image: string | undefined;
    }[];
    farm_operator: {
      username: string;
    };
    sold_out?: boolean;
    efficiency?: number;
    uptime?: number;
    timeOnline?: string;
    trial?: boolean;
  }[];
  images: {
    image: string | undefined;
  }[];
  amount: number;
};

export type MarketplaceData = {
  count: number;
  next?: string;
  previous?: string;
  results: Marketplace[];
  batches: GroupedBatch[];
};
export type FarmOperatorsPageData = {
  count: number;
  next?: string;
  previous?: string;
  results: FarmOperatorsPage[];
};
export type InUseDetailsData = {
  count: number;
  next?: string;
  previous?: string;
  results: InUseDetails[];
};
export type ResellerDetailsData = {
  id: string;
  username: string;
  image: string;
  rating: number;
  reviews_count: number;
  items_in_stock: number;
  items_sold: number;
  description: string;
  created_at: string;
};
export type FarmOperatorDetailsData = {
  id: string;
  first_name: string;
  last_name: string;
  description: string;
  image: string;
  rating: number;
  reviews_count: number;
  audited: boolean;
  monthly_electricity_cost: {
    m2_price: number;
    m3_price: number;
    m6_price: number;
    m12_price: number;
  };
  created_at: string;
  available_hardware: number;
  items_on_zionodes: number;
  youtube_link: string;
  coming_soon: boolean;
  available_items_fake: number;
  total_items_fake: number;
  total_hashrate_fake: number;
};
export type HardwareCardsData = {
  os_version: string;
  free_dp: string;
  average_load: {
    value: number;
    time: string;
  }[];
  fans: {
    index: number;
    fan: number;
    fan_rpm: number;
  }[];
  boards: {
    chain: number;
    asic_number: number;
    frequency: number;
    temp: number;
    status: number[];
  }[];
};
export type FarmOperatorDescriptionData = {
  location: number;
  energy_source: number;
  total_area: number;
  total_capacity: number;
  operates_since: string;
  operating_team: number;
  stability_of_operations: number;
  safety_level: number;
  regional_policies: number;
  tracking_record: number;
  transparency: number;
  rating: number;
  final_opinion: number;
};
export type ResellerHardwareData = {
  count: number;
  next?: string;
  previous?: string;
  results: ResellerHardware[];
};
export type FarmOperatorHardwareData = {
  count: number;
  next?: string;
  previous?: string;
  results: FarmOperatorHardware[];
};
export type ResellerReviewsData = {
  count: number;
  next?: string;
  previous?: string;
  results: ResellerReviews[];
};
export type FarmOperatorReviewsData = {
  count: number;
  next?: string;
  previous?: string;
  results: FarmOperatorReviews[];
};
export type InUseFilters = {
  models: {
    model: string;
  }[];
  farm_operators: {
    id: string;
    fo_username: string;
  }[];
  purchase_date: string;
};
export type MarketplaceFilters = {
  models: {
    model: string;
  }[];
  resellers: {
    id: string;
    reseller_username: string;
  }[];
  farm_operators: {
    id: string;
    fo_username: string;
  }[];
  min: number;
  max: number;
};
export type AsicsModels = {
  results: {
    id: string;
    model: string;
  }[];
};
export type FarmOperators = {
  count: number;
  next: string;
  previous: string;
  results: {
    id: string;
    image: string;
    username: string;
    rating: number;
    reviews_count: number;
    created_at: string;
    total_hashrate: number;
    monthly_electricity_cost: {
      m2_price: number;
      m12_price: number;
    };
    available_hardware: number;
    items_on_zionodes: number;
    instruction: string;
    available_items_fake: number;
    total_items_fake: number;
    total_hashrate_fake: number;
  }[];
};
export type FarmOperatorsContacts = {
  telegram: string;
  whatsapp: string;
  wechat: string;
};

export type FarmOperatorType = {
  id: string;
  image?: string;
  rating: number;
  address?: string;
  reviews_count: number;
  city?: string;
  country?: string;
  postal_code?: string;
  description?: string;
  contact_email?: string;
  phone?: string;
  username?: string;
  email?: string;
  first_name: string;
  last_name: string;
  support_price?: Number;
  youtube_link: string;
  audited?: Boolean;
  total_hashrate: number;
  items_on_zionodes: number;
  available_hardware: number;
  monthly_electricity_cost: {
    m2_price: number;
    m3_price: number;
    m6_price: number;
    m12_price: number;
  };
  created_at: string;
};

export type RegisterSuccessResponseType = {
  success: string;
};

export type RegisterBadRequestType = {
  email: string[];
  password1: string[];
  password2: string[];
  role: string[];
};

export type RegisterResellerBadRequestType = {
  email: string[];
  password1: string[];
  password2: string[];
};

export enum RoleEnum {
  User = 'USER',
  Reseller = 'RESELLER',
}
export enum ResellerRoleEnum {
  Personal = 'PERSONAL',
  Company = 'COMPANY',
}

export type UserDataType = {
  username?: string;
  first_name: string;
  last_name: string;
  nationality: string;
  email: string;
  is_active_email: string;
  role: RoleEnum;
  image: string;
};

export type FarmOperatorAuditType = {
  rating: string;
  location: number;
  energy_source: number;
  total_area: number;
  total_capacity: number;
  operates_since: string;
  operating_team: number;
  stability_of_operations: number;
  safety_level: number;
  regional_policies: number;
  tracking_record: number;
  transparency: number;
  final_opinion: string;
};

export type AsicStatisticType = {
  statistic: Array<StatItemType>;
  hashrates: StatHashratesType;
};

export type StatItemType = {
  time: number;
  revenue_btc: number;
  revenue_usd: number;
  consumption_usd: number;
  units: string;
  power: number;
  profit: number;

  temp: Array<number>;
  fan: Array<number>;
  hashrates: { algo: string; values: Array<number> }[];
};

type StatHashratesType = {
  sha256: Array<StatHashrateItemType>;
};

export type StatHashrateItemType = {
  time: number;
  hashrate: number;
};

export type FarmOperatorHardwareType = {
  results: Array<HardwareType>;
};

export type HardwareType = {
  income: number;
  items: number;
  model: string;
  declared_hashrate: number;
  nominal_consumption: number;
  image: string;
  batches: Array<Batch>;
};

type Batch = {
  reseller_id: string;
  reseller_username: string;
  reseller_image: string;
  reseller_rating: number;
  reseller_reviews_count: number;
  description: string;
  id: string;
  electricity_prices: {
    m2_price: number;
    m3_price: number;
    m6_price: number;
    m12_price: number;
  };
  prices: Array<BatchPrice>;
};

type BatchPrice = {
  min_quantity: number;
  max_quantity: number;
  price: number;
};

type UserType = {
  id: string;
  password: string;
  last_login: string;
  is_superuser: boolean;
  username?: string;
  first_name: string;
  last_name: string;
  email: string;
  is_staff: boolean;
  is_active: boolean;
  date_joined: string;
  role: string;
  reseller_type: string;
  is_active_email: boolean;
  is_banned: boolean;
  is_kyc_confirmed: boolean;
  tmp_email: string;
  old_email: string;
  created_at: string;
  updated_at: string;
};

export type MarketplaceItemHardwareType = {
  id: string;
  worker_id: number;
  farm_id: number;
  fs_id: number;
  mac_address: string;
  uptime: number;
  status: string;
  owner: UserType;
  reseller: UserType;
  model: any;
  batch: Batch;
};

export type ElectricityPaymentsInfo = {
  date: string;
  batch_id: string;
  models: {
    model: string;
    items_count: number;
  }[];
  income_per_month: number;
  days_left: number;
  months_of_electricity: number;
  total_prices: {
    ['1m_price']: number;
    ['2m_price']: number;
    ['3m_price']: number;
    ['6m_price']: number;
    ['12m_price']: number;
  };
};

export type Billing = {
  total_electricity_cost_per_month: number;
  balance: number;
  paid_days_of_electricity: number;
  electricity_payments_info: ElectricityPaymentsInfo[];
};

export type DashboardCards = {
  balance: number;
  balance_in_btc: number;
  hashrate: number;
  ave_weekly_hash: number;
  income_per_day: number;
  income_by_models: Array<IncomeByModels>;
  income_per_day_in_btc: number;
  total_hardware_units: number;
  boards: number;
};

export type DashboardSales = Array<DashboardSale>;

export type DashboardSale = {
  date: string;
  value: number;
  price: number;
};

export type IncomeByModels = {
  model: string;
  income: string;
};

export type DashboardHashrate = {
  hashrate: number;
  date: string;
};

export type DashboardElectricityIncome = {
  net_income: number;
  electricity_cost: number;
  date: string;
};

export enum UserTransactionType {
  Order = 'ORDER',
  Deposit = 'DEPOSIT',
  Electricity = 'ELECTRICITY',
}

export enum PutUpRemoveType {
  Put = 'PUT_UP',
  Remove = 'REMOVE',
}

export enum PutUpRemoveAllType {
  PutCouple = 'PUT_COUPLE',
  RemoveCouple = 'REMOVE_COUPLE',
  PutAll = 'PUT_ALL',
  RemoveAll = 'REMOVE_ALL',
}

export enum ModelStatusType {
  New = 'NEW',
  PreMine = 'PRE_MINE',
  ForSale = 'FOR_SALE',
  OnSale = 'ON_SALE',
  Sold = 'SOLD',
}

export enum SpecialProfileContactType {
  Telegram = 'TELEGRAM',
  WeChat = 'WECHAT',
  WhatsApp = 'WHATSAPP',
}

export type UserTransactionHistory = {
  id: string;
  type: UserTransactionType;
  text_type: string;
  description: string;
  amount: string;
  current_balance: string;
  created_at: string;
};

export type UserTransactionHistoryReseller = {
  id: string;
  type: UserTransactionType;
  description: string;
  text_type: string;
  batch: string | null;
  model: string | null;
  items_count: number | null;
  item_price: number | null;
  created_at: string;
};

export type UserTransactionHistoryPage = {
  count: number;
  next: string;
  previous: string;
  results: UserTransactionHistory[];
};

export type UserTransactionHistoryResellerPage = {
  count: number;
  next: string;
  previous: string;
  results: UserTransactionHistoryReseller[];
};

export type BatchInfo = {
  id: string;
};

export type UserInfo = {
  id: string;
  username?: string;
  first_name: string;
  last_name: string;
  email: string;
  is_active_email: boolean;
  role: RoleEnum;
};

export type WithdrawalInfo = {
  coinpayments_id?: string;
  address: string;
  amount: number;
};

export type B2bTopUpInfo = {
  address: string;
  amount: number;
  pow: number;
  currency: CurrencyEnum;
  message?: string;
};

export type PostResellerReviewInfo = {
  text: string;
  score: number;
};

export type PostFarmOperatorReviewInfo = {
  text: string;
  score: number;
};

export type UserProfileInfo = {
  address: string;
  nationality: string;
  birthday: string;
  phone: string;
};

export type UserSpecialInfo = {
  description: string;
  contact_email: string;
  is_active_contact_email: boolean;
  spec_phone: string;
  reseller_type: ResellerRoleEnum;
};

export type UserCompanyInfo = {
  company_name: string;
  br_number: string;
  registered_address: string;
  registered_address_postal_code: string;
  company_email: string;
  country: string;
  business_address: string;
  business_address_postal_code: string;
};

export type UserLegalRepresentativeInfo = {
  first_name: string;
  last_name: string;
  birthday: string;
  email: string;
  nationality: string;
  job_title: string;
  phone_number: string;
};

export type PatchUserParams = {
  // undefined - image haven't been changed, null - image deleted
  image: File | undefined | null;
  data: {
    clear_image: boolean;
    username: string;
    email: string;
    first_name: string;
    last_name: string;
    passw: string;
  };
};

export type PostBatchParams = {
  images: [File, File | undefined, File | undefined, File | undefined];
  data: {
    prices: {
      min_quantity: number;
      max_quantity: number;
      price: number;
    }[];
    amount: number;
    description: string;
    purchase_date: string;
    model: string;
    farm_operator: string;
    type: 'ASIC';
  };
};

export type PatchUserProfileParams = {
  address: string;
  nationality: string;
  birthday: string;
  phone: string;
  passw: string;
};

export type PatchUserProfileSpecialParams = {
  image: File | undefined | null;
  data: {
    clear_wechat_image: boolean;
    description: string;
    contact_email: string;
    contacts: {
      telegram: string;
      whatsapp: string;
    };
    passw: string;
  };
};

export type PatchUserProfileCompanyParams = {
  company_name: string;
  br_number: string;
  registered_address: string;
  registered_address_postal_code: string;
  company_email: string;
  country: string;
  business_address: string;
  business_address_postal_code: string;
  passw: string;
};

export type PatchAsicGeneralConfigurationParams = {
  pool_1: string;
  pool_2: string;
  pool_3: string;
  template: string;
  password?: string;
};

export type PatchUserLegalRepresentativeParams = {
  first_name: string;
  last_name: string;
  birthday: string;
  email: string;
  nationality: string;
  job_title: string;
  phone_number: string;
  passw: string;
};

export type WithdrawalParams = {
  amount: number;
  address: string;
  currency: CoinsEnum;
  code?: string;
};

export type WithdrawalApiParams = {
  amount: number;
  address: string;
  currency: CurrencyEnum;
  code?: string;
};

export enum NetworksEnum {
  ETH = 'ETH',
  BSC = 'BSC',
  TRX = 'TRX',
}

export enum CurrencyEnum {
  USDC_ETH = 'USDC-ETH',
  USDT_ETH = 'USDT-ETH',
  USDP_ETH = 'USDP-ETH',
  TUSD_ETH = 'TUSD-ETH',
  BUSD_T_BSC = 'BUSD-T-BSC',
  DAI_ETH = 'DAI-ETH',
  BUSD_ETH = 'BUSD-ETH',
  BUSD_BSC = 'BUSD-BSC',
  DAI_BSC = 'DAI-BSC',
  USDC_BSC = 'USDC-BSC',
  USDP_BSC = 'USDP-BSC',
  USDC_TRX = 'USDC-TRX',
  USDT_TRX = 'USDT-TRX',
  UST_ETH = 'UST-ETH',
  UST_BSC = 'UST-BSC',
}

export enum CoinsEnum {
  USDT = 'USDT',
  USDC = 'USDC',
  USDP = 'USDP',
  TUSD = 'TUSD',
  DAI = 'DAI',
  BUSD = 'BUSD',
}

export type B2bTopUpParams = {
  amount: number;
  currency: CurrencyEnum;
  deposit_id?: string;
};

export type ApplyDiscountParams = {
  code: string;
};

export type Discount = {
  amount_usdt: number;
  amount_usdt_last: number;
  code: string;
  percent_discount: number;
  percent_referral: number;
  type_discount: string;
  used_at: Date;
  user_owner: string;
  user_used: string;
};

export type DiscountCodes = {
  codes: {
    code: string;
    used: boolean;
  }[];
  total_used_usdt: {
    amount_usdt__sum: number | null;
  };
};

export type PostResellerReviewParams = {
  score: number;
  text: string;
};

export type PostFarmOperatorReviewParams = {
  score: number;
  text: string;
};

export type Activate2FAInfo = {
  qr_link: string;
};

export type Active2FAMethodsInfo = {
  name: 'app';
  is_primary: boolean;
}[];

export type KYCVerificationUrlResponse = {
  verification_form_url: string;
};

export type KYCVerificationUrlParams = {
  response_url: string;
};

export enum KYCStatus {
  Unused = 'unused',
  Pending = 'pending',
  Completed = 'completed',
}

export type KYCStatusResponse = {
  status: KYCStatus;
  verified: boolean;
};

export type MerchantIdResponse = {
  merchant_id: number;
};

export type PatchUserResult = UserInfo;

export type PostBatchResult = BatchInfo;

export type WithdrawalResult = WithdrawalInfo;

export type B2bTopUpResult = B2bTopUpInfo;

export type PostResellerReviewResult = PostResellerReviewInfo;

export type PostFarmOperatorReviewResult = PostFarmOperatorReviewInfo;

export type PatchUserProfileResult = UserProfileInfo;

export type PatchUserSpecialResult = UserSpecialInfo;

export type PatchUserCompanyResult = UserCompanyInfo;

export type PatchAsicGeneralConfigurationResult = AsicGeneralConfigurationType;

export type PatchUserLegalRepresentativeResult = UserLegalRepresentativeInfo;

export interface LoginApiResponse extends AxiosResponse {
  data: LoginDataType;
}

export interface AsicsApiResponse extends AxiosResponse {
  data: AsicsDataType;
}

export interface AsicApiResponse extends AxiosResponse {
  data: AsicDataType;
}

export interface FarmOperatorsResponse extends AxiosResponse {
  data: { results: Array<FarmOperatorType> };
}

export interface HashrateResponse extends AxiosResponse {
  data: { income: number };
}

export interface FarmOperatorResponse extends AxiosResponse {
  data: FarmOperatorType;
}

export interface FarmOperatorAuditResponse extends AxiosResponse {
  data: FarmOperatorAuditType;
}

export interface FarmOperatorHardwareResponse extends AxiosResponse {
  data: FarmOperatorHardwareType;
}

export interface MarketplaceItemHardwareResponse extends AxiosResponse {
  data: { results: MarketplaceItemHardwareType[] };
}

export interface RegisterResponse extends AxiosResponse {
  data: RegisterSuccessResponseType | RegisterBadRequestType;
}

export interface RegisterCompanyResponse extends AxiosResponse {
  data: RegisterSuccessResponseType | RegisterResellerBadRequestType;
}

export interface UserDataResponse extends AxiosResponse {
  data: UserDataType;
}

export interface ConfirmEmailResponse extends AxiosResponse {
  data: JwtData;
}

export interface ApiInterface {
  loginUser(data: { username: string; password: string }): Promise<LoginApiResponse>;
  getMarketplaceItems(arg: UseMarketplaceArg): Promise<AsicsApiResponse>;
  getMarketplaceItem(id: string): Promise<AsicApiResponse>;
  getFarmOperators(page: number): Promise<FarmOperatorsResponse>;
  getFarmOperatorById(id: string): Promise<FarmOperatorResponse>;
  getFarmOperatorAudit(id: string): Promise<FarmOperatorAuditResponse>;
  getFarmOperatorAvailableHardware(id: string): Promise<FarmOperatorHardwareResponse>;
  postFarmOperatorReview(data: { id: string; score: number; text: string }): Promise<any>;
  getMarketplaceItemHardware(id: string): Promise<MarketplaceItemHardwareResponse>;
  registerUser(data: {
    first_name: string;
    email: string;
    password1: string;
    password2: string;
    role: string;
  }): Promise<RegisterResponse>;
  getUserData(userId: string, token: string): Promise<UserDataResponse>;
  registerCompanyReseller(data: {
    first_name: string;
    email: string;
    password1: string;
    password2: string;
  }): Promise<RegisterCompanyResponse>;
  getMyHardware(filters?: MyHardwareFilters): Promise<AxiosResponse<MyHardwareData>>;
  downloadTransactionsHistory(): Promise<void>;
  downloadResellerTransactionsHistory(): Promise<void>;
}
